import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Portal from "../Portal";

const BaseModal = ({
  isOpen,
  toggle,
  title,
  children,
  footer,
  size = "md",
  className,
  hideHeader = false,
  centered = true,
  ...props
}) => {
  return (
    <Portal>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size={size}
        className={className}
        centered={centered}
        {...props}
      >
        {!hideHeader && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
        <ModalBody>{children}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </Modal>
    </Portal>
  );
};

export default BaseModal;
