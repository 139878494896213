import React from "react";
import { Button, Row, Col } from "reactstrap";
import BaseModal from "./BaseModal";

const TimeoutModal = ({ isOpen, toggle }) => {
  const footer = (
    <Button color="primary" onClick={toggle}>
      OK
    </Button>
  );

  return (
    <BaseModal
      isOpen={isOpen}
      toggle={toggle}
      title="Tempo expirado"
      footer={footer}
      className="d-flex flex-column"
      style={{ gap: "10rem" }}
    >
      <Row>
        <Col>
          <h1 className="text-center">Tempo para análise expirado</h1>
        </Col>
      </Row>
      <Row>
        <Col>Tempo limite de análise excedido.</Col>
      </Row>
    </BaseModal>
  );
};

export default TimeoutModal;
