import React from "react";
import { Button } from "reactstrap";
import BaseModal from "./BaseModal";

const ErrorModal = ({ isOpen, toggle, msg }) => {
  const footer = (
    <Button color="secondary" onClick={toggle}>
      OK
    </Button>
  );

  return (
    <BaseModal isOpen={isOpen} toggle={toggle} title="Erro!" footer={footer}>
      {msg}
    </BaseModal>
  );
};

export default ErrorModal;
