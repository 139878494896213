import React from "react";
import { FormGroup, Label, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import "./styles.sass";

const TimePicker = ({
  timePick,
  onChangeTime,
  labelText,
  max,
  min,
  disabled,
  invalid,
  errorMessage,
  icon: Icon,
  tooltipId,
  tooltipText,
  oldVersion = true,
}) => {
  if (oldVersion) {
    return (
      <FormGroup
        className={`time-input-old ${invalid && "is-invalid"}`}
        style={disabled ? { backgroundColor: "#E3E3E3" } : {}}
      >
        <Label
          for="time-initial"
          style={{ color: "#6C757D", marginRight: "0.25rem" }}
        >
          {labelText}
        </Label>
        <input
          type="time"
          value={timePick}
          max={max}
          min={min}
          onChange={onChangeTime}
          style={{ border: 0 }}
          disabled={disabled}
        />
      </FormGroup>
    );
  }

  return (
    <FormGroup className="time-input" data-testid="time-input">
      <Label
        className={classnames("form-control-label", {
          "text-danger": invalid && !disabled,
          "text-muted": disabled,
        })}
        data-testid="time-picker-label"
      >
        {labelText}
        {Icon && (
          <>
            <Icon id={tooltipId} size={16} style={{ color: "#404243" }} />
            {tooltipText && (
              <UncontrolledTooltip placement="top" target={tooltipId}>
                {tooltipText}
              </UncontrolledTooltip>
            )}
          </>
        )}
      </Label>

      <input
        type="time"
        data-testid="time-picker-input"
        value={timePick}
        max={max}
        min={min}
        onChange={onChangeTime}
        disabled={disabled}
        className={classnames({
          "is-invalid": invalid && !disabled,
          "is-disabled": disabled,
        })}
      />
      {invalid && !disabled && errorMessage && (
        <div className="invalid-feedback">{errorMessage}</div>
      )}
    </FormGroup>
  );
};

export default TimePicker;
