import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { isInside } from "overlap-area";
import { isFinite } from "lodash";

const TOKEN_KEY = "TOKEN";

export const formatDate = (date) => {
  if (!!date && !isNaN(date)) {
    const withOffset = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const strDate = withOffset.toISOString().replace("T", " ");
    return strDate.substr(0, 10);
  }
  return "";
};

export const formatDateGMT = (date) => {
  if (!!date && !isNaN(date)) {
    const withOffset = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return withOffset.toISOString();
  }
  return "";
};

export const formatDateDayMonth = (date) =>
  new Date(date + " ").toLocaleString().split(",")[0];

const LETTERS = Array.from({ length: 10 }, (_, i) =>
  String.fromCharCode(i + 65)
);

export const formatPlate = (plate) =>
  plate && isFinite(+plate[4])
    ? formatPlateNewPatten(plate)
    : formatPlateToOldPattern(plate);

export const formatPlateNewPatten = (plate) => {
  if (!plate || plate.length < 7) {
    return plate || "";
  }
  const index = LETTERS.indexOf(plate[4]);
  if (index !== -1) {
    return plate;
  }
  return plate.slice(0, 4) + LETTERS[plate[4]] + plate.slice(5);
};

export const formatPlateToOldPattern = (plate) => {
  if (!plate || plate.length < 7) {
    return plate || "";
  } else if (isFinite(+plate[4])) {
    return plate;
  }
  let formattedPlate = [
    plate.slice(0, 4),
    LETTERS.indexOf(plate.charAt(4)),
    plate.slice(5),
  ].join("");
  return formattedPlate;
};

export const truncateText = (text, maxNum) => {
  const textLength = text.length;
  return textLength > maxNum ? `${text.slice(0, maxNum)}...` : text;
};

export function calcWidth() {
  return (window.outerWidth / 100) * ((100 / 12) * 10);
}

export function getCurrentUser() {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!!token) {
    return jwtDecode(token);
  }
  return {};
}

export function isAllow(roles) {
  const user = getCurrentUser();
  return (
    roles.length === 0 ||
    roles.some((role) => user?.Role?.includes(role.toString()))
  );
}

export function isPartialPlateValid(plate) {
  let isValid = plate.length < 8;
  if (plate !== "") {
    isValid = isValid && plate.search(/\D{1,3}/) === 0;
    if (plate.length > 3) {
      isValid = isValid && plate.search(/\d/) === 3;
      if (plate.length > 4) {
        isValid =
          isValid &&
          plate
            .charAt(4)
            .toUpperCase()
            .search(/\d|[A-J]/) === 0;
        if (plate.length > 5) {
          isValid = isValid && plate.substring(5).search(/\d{1,2}/) === 0;
        }
      }
    }
  }
  return isValid;
}

export function isPlateValid(plate) {
  let isValid = plate.length === 7;
  if (plate !== "") {
    isValid = isValid && plate.search(/\w{1,3}/) === 0;
    if (plate.length > 3) {
      isValid = isValid && plate.search(/\d{1,2}/) === 3;
      if (plate.length > 5) {
        isValid =
          isValid &&
          plate
            .charAt(5)
            .toUpperCase()
            .search(/\d|[A-J]/) === 0;
        if (plate.length > 6) {
          isValid = isValid && plate.charAt(6).search(/\d/) === 0;
        }
      }
    }
  }
  return isValid;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function objectToQueryString(obj) {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function zeroPad(num, length) {
  return String(num).padStart(length, "0");
}

export function phoneValidate(phone) {
  phone = phone.replace(/\D/g, "");
  const length = phone.length;

  if (length < 10 || length > 11) {
    return false;
  }

  const ddd = parseInt(phone.substring(0, 2));
  const validDDDs = [
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    21,
    22,
    24,
    27,
    28,
    31,
    32,
    33,
    34,
    35,
    37,
    38,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    51,
    53,
    54,
    55,
    61,
    62,
    64,
    63,
    65,
    66,
    67,
    68,
    69,
    71,
    73,
    74,
    75,
    77,
    79,
    81,
    82,
    83,
    84,
    85,
    86,
    87,
    88,
    89,
    91,
    92,
    93,
    94,
    95,
    96,
    97,
    98,
    99,
  ];

  if (!validDDDs.includes(ddd)) {
    return false;
  }

  //Se tiver 11 caracteres, verificar se começa com 9 o celular
  const firstDigit = parseInt(phone.charAt(2));
  if (length === 11 && firstDigit !== 9) {
    return false;
  }

  //Se o número só tiver dez digitos não é um celular e por isso o número logo após o DDD deve ser 2, 3, 4, 5 ou 7
  const prefix = [2, 3, 4, 5, 7];
  if (length === 10 && !prefix.includes(firstDigit)) {
    return false;
  }

  // verifica se o numero foi digitado com todos os dígitos iguais
  if (phone.split("").every((char) => char === phone[0])) {
    return false;
  }

  return true;
}

export function array_move(arr, old_index, new_index) {
  const newArray = [...arr];
  if (new_index >= newArray.length) {
    let k = new_index - newArray.length + 1;
    while (k--) {
      newArray.push(undefined);
    }
  }
  newArray.splice(new_index, 0, newArray.splice(old_index, 1)[0]);
  return newArray; // for testing
}

const mapCoordinate = (coordinate) =>
  coordinate.split(",").map((c) => parseInt(c));

// Função para recortar a imagem do carro baseada nas coordenadas da placa
export function cropCar(source, coordinates, imageWidth, imageHeight) {
  return new Promise((resolve, reject) => {
    // Validação inicial dos parâmetros
    if (!coordinates || !imageWidth || !imageHeight) {
      console.warn("Parâmetros inválidos:", {
        coordinates,
        imageWidth,
        imageHeight,
      });
      return resolve(source);
    }

    let img = new Image();

    img.onload = () => {
      try {
        // Define os limites da imagem fonte para validação
        const sourceRect = [
          [0, 0], // topo-esquerda
          [img.width, 0], // topo-direita
          [img.width, img.height], // base-direita
          [0, img.height], // base-esquerda
        ];

        // Converte a string de coordenadas (x1,y1;x2,y2;x3,y3;x4,y4) em array de pontos
        const coord = coordinates.split(";").map(mapCoordinate);

        // Se alguma coordenada estiver fora da imagem, retorna imagem original
        if (coord.some((c) => !isInside(c, sourceRect))) {
          return resolve(source);
        }

        // Calcula margens de segurança (0.5% das dimensões)
        const marginWidth = 0.005 * imageWidth;
        const marginHeight = 0.005 * imageHeight;

        // Extrai e ajusta as coordenadas com margens de segurança
        const xs = [
          parseInt(coord[0][0]) - marginWidth, // X superior esquerdo
          parseInt(coord[1][0]) + marginWidth, // X superior direito
          parseInt(coord[2][0]) + marginWidth, // X inferior direito
          parseInt(coord[3][0]) - marginWidth, // X inferior esquerdo
        ];
        const ys = [
          parseInt(coord[0][1]) - marginHeight, // Y superior esquerdo
          parseInt(coord[1][1]) - marginHeight, // Y superior direito
          parseInt(coord[2][1]) + marginHeight, // Y inferior direito
          parseInt(coord[3][1]) + marginHeight, // Y inferior esquerdo
        ];

        // Calcula dimensões e centro da placa
        let minX = Math.min(...xs);
        let minY = Math.min(...ys);
        let maxX = Math.max(...xs);
        let maxY = Math.max(...ys);
        const plateWidth = maxX - minX;
        const plateHeight = maxY - minY;
        const plateCenterX = (minX + maxX) / 2;
        const plateCenterY = (minY + maxY) / 2;

        // Calcula tamanho do quadrado (4x maior que a placa + margens)
        const squareSize =
          Math.max(plateWidth, plateHeight) * 4 + marginWidth * 2;

        // Calcula limites do corte centralizado na placa
        let cropX = plateCenterX - squareSize / 2;
        let cropY = plateCenterY - squareSize / 2;
        let cropWidth = squareSize;
        let cropHeight = squareSize;

        // Ajusta dimensões se ultrapassarem os limites da imagem
        if (cropX < 0) {
          cropWidth += cropX; // Reduz largura pelo excesso
          cropX = 0;
        }
        if (cropY < 0) {
          cropHeight += cropY; // Reduz altura pelo excesso
          cropY = 0;
        }
        if (cropX + cropWidth > imageWidth) {
          cropWidth = imageWidth - cropX;
        }
        if (cropY + cropHeight > imageHeight) {
          cropHeight = imageHeight - cropY;
        }

        // Cria canvas quadrado para output final
        const finalCanvas = document.createElement("canvas");
        finalCanvas.width = squareSize;
        finalCanvas.height = squareSize;
        const finalCtx = finalCanvas.getContext("2d");

        // Desenha fundo borrado (evita fundo branco)
        finalCtx.filter = "blur(20px)";
        finalCtx.drawImage(img, 0, 0);
        finalCtx.filter = "none";

        // Calcula offsets para centralizar a imagem principal
        const mainOffsetX = Math.max(0, (squareSize - cropWidth) / 2);
        const mainOffsetY = Math.max(0, (squareSize - cropHeight) / 2);

        // Desenha a porção principal da imagem (não borrada)
        finalCtx.drawImage(
          img,
          cropX,
          cropY,
          cropWidth,
          cropHeight, // Retângulo fonte
          mainOffsetX,
          mainOffsetY,
          cropWidth,
          cropHeight // Retângulo destino
        );

        // Ajusta coordenadas da placa relativas ao novo canvas
        const adjustedXs = xs.map((x) => x - cropX + mainOffsetX);
        const adjustedYs = ys.map((y) => y - cropY + mainOffsetY);

        // Desenha retângulo verde ao redor da placa
        finalCtx.strokeStyle = "lawngreen";
        finalCtx.lineWidth = 3;
        finalCtx.beginPath();
        finalCtx.moveTo(adjustedXs[0], adjustedYs[0]);
        finalCtx.lineTo(adjustedXs[1], adjustedYs[1]);
        finalCtx.lineTo(adjustedXs[2], adjustedYs[2]);
        finalCtx.lineTo(adjustedXs[3], adjustedYs[3]);
        finalCtx.lineTo(adjustedXs[0], adjustedYs[0]);
        finalCtx.stroke();

        // Converte canvas para blob e retorna URL
        finalCanvas.toBlob((blob) => {
          setTimeout(() => finalCanvas.remove(), 100);
          if (!blob) {
            reject("Canvas is empty");
          } else {
            blob.name = `${new Date().getTime()}.jpg`;
            resolve(window.URL.createObjectURL(blob));
          }
        }, "image/jpeg");
      } catch (e) {
        console.error("Crop failed:", e);
        resolve(source);
      }
    };

    img.src = source;
  });
}

export function cropPlate(source, coordinates, imageWidth, imageHeight) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      const coord = coordinates.split(";").map(mapCoordinate);
      const marginWidth = 0.005 * imageWidth;
      const marginHeight = 0.005 * imageHeight;
      const xs = [
        coord[0][0] - marginWidth,
        coord[1][0] + marginWidth,
        coord[2][0] + marginWidth,
        coord[3][0] - marginWidth,
      ];
      const ys = [
        coord[0][1] - marginHeight,
        coord[1][1] - marginHeight,
        coord[2][1] + marginHeight,
        coord[3][1] + marginHeight,
      ];
      const minX = Math.min(...xs);
      const minY = Math.min(...ys);
      const maxX = Math.max(...xs);
      const maxY = Math.max(...ys);
      if (imageWidth < minX || imageHeight < minY) {
        resolve();
        return;
      }
      const height = Math.abs(maxY - minY);
      const width = Math.abs(maxX - minX);
      if (imageWidth < width || imageHeight < height) {
        resolve();
        return;
      }
      const pixelCrop = {
        x: minX,
        y: minY,
        height,
        width,
      };
      const canvas = document.createElement("canvas");
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext("2d");
      try {
        ctx.drawImage(
          img,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
      } catch (e) {
        console.error(e);
        reject(e);
        return;
      }

      canvas.toBlob((blob) => {
        setTimeout(() => canvas.remove(), 100);
        if (!blob) {
          console.warn("Canvas is empty");
          reject("Canvas is empty");
        } else {
          blob.name = `plate_${new Date().getTime()}.jpg`;
          resolve(window.URL.createObjectURL(blob));
        }
      }, "image/jpeg");
    };
    img.src = source;
  });
}

export function drawBBoxPlate(source, coordinates) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      let coord = coordinates
        .split(";")
        .map((coordinate) => coordinate.split(",").map((c) => parseInt(c)));
      const marginWidth = 0.005 * img.width;
      const marginHeight = 0.005 * img.height;
      let xs = [
        parseInt(coord[0][0]) - marginWidth,
        parseInt(coord[1][0]) + marginWidth,
        parseInt(coord[2][0]) + marginWidth,
        parseInt(coord[3][0]) - marginWidth,
      ];
      let ys = [
        parseInt(coord[0][1]) - marginHeight,
        parseInt(coord[1][1]) - marginHeight,
        parseInt(coord[2][1]) + marginHeight,
        parseInt(coord[3][1]) + marginHeight,
      ];
      const canvas = document.createElement("canvas");
      canvas.height = img.height;
      canvas.width = img.width;
      const ctx = canvas.getContext("2d");
      try {
        ctx.drawImage(img, 0, 0);
        ctx.strokeStyle = "lawngreen";
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(xs[0], ys[0]);
        ctx.lineTo(xs[1], ys[1]);
        ctx.lineTo(xs[2], ys[2]);
        ctx.lineTo(xs[3], ys[3]);
        ctx.lineTo(xs[0], ys[0]);
        ctx.stroke();
      } catch (e) {
        reject(e);
        return;
      }
      img.remove();
      canvas.toBlob((blob) => {
        setTimeout(() => canvas.remove(), 100);
        if (!blob) {
          reject("Canvas is empty");
        } else {
          blob.name = `${new Date().getTime()}.jpg`;
          resolve(window.URL.createObjectURL(blob));
        }
      }, "image/jpeg");
    };
    img.src = source;
  });
}

export function importPreviewCSV(chunkSize, callback, fallback = () => {}) {
  return ([file]) => {
    const fr = new FileReader();
    const CHUNK_SIZE = chunkSize;
    fr.onload = function({ target: { result } }) {
      let buffer = new Uint8Array(result);
      let text = new TextDecoder("windows-1252").decode(buffer);
      let results = [];
      const lines = text.split(/\r?\n/);
      const titles = lines[0].replace("ï»¿", "").split(";");
      const lastLine = lines[lines.length - 1].split(";");
      if (titles.length !== lastLine.length) {
        lines.pop();
      }
      const len = lines.length > 21 ? 21 : lines.length;
      for (let index = 1; index < len; index++) {
        if (!lines[index]) {
          break;
        }
        const line = lines[index].split(";");
        if (line.length !== titles.length) {
          fallback(`A linha ${index} e o cabeçalho tem tamanhos incompativeis`);
          break;
        }
        const objLine = titles
          .map((element, index2) => [element, line[index2]])
          .reduce((acc, curr) => {
            acc[curr[0].toUpperCase()] = curr[1];
            return acc;
          }, {});
        results = [...results, objLine];
      }
      callback(results, file);
    };
    const end = file.size > CHUNK_SIZE ? CHUNK_SIZE : file.size;
    const slice = file.slice(0, end);
    fr.readAsArrayBuffer(slice);
  };
}

export const ufs = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export function removeAccents(text) {
  text = text.toUpperCase();
  text = text.replace(/[ÁÀÂÃ]/gi, "A");
  text = text.replace(/[ÉÈÊ]/gi, "E");
  text = text.replace(/[ÍÌÎ]/gi, "I");
  text = text.replace(/[ÓÒÔÕ]/gi, "O");
  text = text.replace(/[ÚÙÛ]/gi, "U");
  text = text.replace(/[Ç]/gi, "C");
  return text;
}

export const copy = (text, message = "Copiado com sucesso!") => {
  navigator.clipboard.writeText(text);
  toast.success(message, {
    hideProgressBar: true,
    autoClose: 3000,
  });
};